<template>
  <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      class="w-5 h-5 text-et-500 mr-2"
      stroke="currentColor"
  >
    <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="4"
        d="M5 13l4 4L19 7"
    />
  </svg>
</template>
<script>
export default {
  name: 'checkYes'
}
</script>