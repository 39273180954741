<template>
  <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      class="w-5 h-5 text-red-700 mr-2"
      stroke="currentColor"
  >
    <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="4"
        d="M6 18L18 6M6 6l12 12"
    />
  </svg>
</template>
<script>
export default {
  name: 'checkNo'
}
</script>